import React, { useState, useEffect } from 'react';
import applogo from './applogo2circle.png'
import { ethers } from 'ethers';
import Button from 'react-bootstrap/Button';
import "./Interact.css";
import "./customdialog.css";
import axios from 'axios';

const networkIdSepolia = "0xaa36a7";
const networkIdSepoliaInt = 11155111;

const networkIdGoerli = "0x5";
const networkIdGoerliInt = 5;

let networkId = networkIdSepolia;

const CONTRACT_ADDRESS_SEPOLIA = process.env.REACT_APP_CONTRACT_ADDRESS_SEPOLIA;
const CONTRACT_ADDRESS_GOERLI = process.env.REACT_APP_CONTRACT_ADDRESS_GOERLI;
const POOL_WALLET = process.env.REACT_APP_PRIZE_WALLET;

const waitingTime = 75; //s
const mmPrice = "0.001";  //0.001eth

const contractFile = require("./artifacts/contracts/SC.sol/SC.json");
const contractAbi = contractFile.abi;

let providerSepolia = (window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum, networkIdSepoliaInt) : ethers.providers.getDefaultProvider(); 
let providerGoerli = (window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum, networkIdGoerliInt) : ethers.providers.getDefaultProvider(); 

let provider = providerSepolia;

// Contract
let contractSepolia = (window.ethereum != null) ? new ethers.Contract(CONTRACT_ADDRESS_SEPOLIA, contractAbi, providerSepolia.getSigner()) : null;
let contractGoerli = (window.ethereum != null) ? new ethers.Contract(CONTRACT_ADDRESS_GOERLI, contractAbi, providerGoerli.getSigner()) : null;
let contract = contractSepolia;

let isMetamaskAlreadyAsked = false;

function updateProviderAndContract() {
  if (provider === null){
    providerSepolia = (window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum, networkIdSepoliaInt) : ethers.providers.getDefaultProvider(); 
    providerGoerli = (window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum, networkIdGoerliInt) : ethers.providers.getDefaultProvider(); 

    provider = providerSepolia;
  }
  if (contract === null) {
    contractSepolia = (window.ethereum != null) ? new ethers.Contract(CONTRACT_ADDRESS_SEPOLIA, contractAbi, providerSepolia.getSigner()) : null;
    contractGoerli = (window.ethereum != null) ? new ethers.Contract(CONTRACT_ADDRESS_GOERLI, contractAbi, providerGoerli.getSigner()) : null;
    contract = contractSepolia;
  }
}

const definitions = ["1)Cute four-legged animal with paw pads.",
"2)Animal symbol of loyalty used by humans for a long time.",
"3)It soars through the sky and represents an entire family of animals.",
"4)One of the twelve signs of the zodiac.",
"5)It loves carrots, but eats almost anything.",
"6)Symbol of slowness, it can, however, move quite swiftly, and some can even swim.",
"7)It loves cheese and frightens some people.",
"8)It can poison his enemy and loves eggs.",
"9)This little animal loves to play and brings a lot of joy to children.",
"10)You can ride it for travel and it can carry heavy loads.",
"11)Seen as a king, it has a very lush fur.",
"12)This feline has a distinctive fur that sets it apart from other similar species."];

const TEST_SEED_PHRASE = process.env.REACT_APP_TEST_SEED;


async function askForAccount() {
  return new Promise(async (resolve, reject) => {
    if (typeof window.ethereum !== 'undefined') {
      updateProviderAndContract();
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        const currentNetworkId = parseInt(chainId, 16);
        if (currentNetworkId !== networkId) {
          const networkChangeRequest = {
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: networkId,
              },
            ],
          };
          await window.ethereum.request(networkChangeRequest);
        }
        resolve(); 
      } catch (error) {
        reject(error); 
      }
    } else if (!isMetamaskAlreadyAsked) {
      isMetamaskAlreadyAsked = true;
      alert("Please install Metamask");
      reject(new Error('Please install Metamask.'));
    }
  });
}


async function getTokenDecimals() {
  const weiPerEther = ethers.constants.WeiPerEther;
  return weiPerEther;
}

async function getBalance(userAddress) {
  const bal = await contract.getBalance(userAddress);
  return bal
}

async function getTryTime(userAddress) {
  const time = await contract.getLastTryTime(userAddress);
  return time
}

async function getPrizePool() {
  const poolWei = await provider.getBalance(POOL_WALLET);
  const pool = ethers.utils.formatEther(poolWei)
  return pool
}

async function getBonus() {
  try {
    const bonusWei = await contract.getBonusPool();
    const bonus = ethers.utils.formatEther(bonusWei)
    return bonus
  } catch (error) {
    console.error('Error: ', error);
  }  
}

async function deposit(am, userAddress) {
  if(am === 0){
    return;
  }

  const balance = await provider.getBalance(userAddress);
  const amWei =  ethers.utils.parseEther(am);
  if(balance < parseFloat(amWei)) {
    alert("not enough balance");
    return
  }
  try {
    const transaction = await contract.deposit({ value: amWei });
    return transaction;
  } catch (error) {
    console.error('Error during deposit:', error);
  }  
}

async function withdraw(am, userAddress) {
  if(am === 0){
    return;
  }

  const balance = await getBalance(userAddress);
  const amWei =  ethers.utils.parseEther(am);
  if(balance < parseFloat(amWei)) {
    alert("overdrawn balance");
    return
  }
  try {
    const transaction = await contract.withdraw(amWei);
    return transaction;
  } catch (error) {
    alert(error);
  }
}

async function propose(price) {
  if (price === 3)
  {
    const transaction = await contract.payForProposal(true);
    return transaction;
  }
  else
  {
    const transaction = await contract.payForProposal(false);
    return transaction;
  }
}

async function checkTransactionStatus(transactionHash) {
  return new Promise((resolve, reject) => {
    provider.getTransactionReceipt(transactionHash)
      .then((receipt) => {
        if (receipt && receipt.status === 1) {
          resolve(true); 
        } else if (receipt && receipt.status === 0) {
          resolve(false); 
        } else {
          setTimeout(function() {
            checkTransactionStatus(transactionHash)
              .then(resolve)
              .catch(reject);
          }, 1000); 
        }
      })
      .catch((error) => {
        alert("can't get transaction status");
        reject(error);
      });
  });
}

async function checkServerSeedStatut() {
  return new Promise((resolve, reject) => {
    axios.get('https://api01.guesstheseed.com/status.php')
      .then(response => {
        const data = response.data;
        console.log(data);       
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = data;

        const tbody = tempDiv.querySelector("tbody");
        const rows = tbody.rows;
        const idSecret = rows[0].cells[0].textContent;
        const statut = rows[0].cells[1].textContent; 
        console.log(`id_secret : ${idSecret}, statut : ${statut}`);
        resolve(statut);                    
      })
      .catch(error => {
        alert("server unreachable");
        reject(error);
      });
    });
}

async function makeRequest(url) {
  return axios.get(url);
}

function showDialog(message) {
  const overlayElement = document.createElement("div");
  overlayElement.className = "custom-dialog-overlay";

  const dialogElement = document.createElement("div");
  dialogElement.className = "custom-dialog";
  
  const contentElement = document.createElement("div");
  contentElement.className = "custom-dialog-content";
  contentElement.innerHTML = message.replace(/\n/g, "<br>");;

  const buttonElement = document.createElement("button");
  buttonElement.className = "custom-dialog-button";
  buttonElement.textContent = "OK";
  buttonElement.addEventListener("click", (event) => {
    event.stopPropagation();
    hideDialog();
  });

  dialogElement.appendChild(contentElement);
  dialogElement.appendChild(buttonElement);

  document.body.appendChild(overlayElement);
  document.body.appendChild(dialogElement);
}

function hideDialog() {
  const overlayElement = document.querySelector(".custom-dialog-overlay");
  const dialogElement = document.querySelector(".custom-dialog");

  if (overlayElement && dialogElement) {
    overlayElement.remove();
    dialogElement.remove();
  }
}

const Interact = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [buttonLabel, setButtonLabel] = useState(null);
    const [buttonMmLabel, setButtonMmLabel] = useState(null);
    const [defaultAccount, setDefaultAccount] = useState(null);
    const [userBalance, setUserBalance] = useState(null);
    const [prizePool, setPrizePool] = useState(null);
    const [bonusPool, setBonusPool] = useState(null);
    const [depositAmount, setDepositAmount] = useState("");
    const [withdrawAmount, setWithdrawAmount] = useState("");
    const [words, setWords] = useState(Array(12).fill(""));
    const [setOfWords, setsetOfWords] = useState([]);
    const [isButtonEnabled, setIsButtonEnabled] = useState(true);
    const [remainingTime, setRemainingTime] = useState(0);
    const [myDelay, setMyDelay] = useState(0);
    const [testMode, setTestMode] = useState(false);
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    
    askForAccount().then(async() => {
      getPrizePool().then((pool) => {
        setPrizePool(parseFloat(pool).toFixed(4));
      });
  
      getBonus().then((bonus) => {
        setBonusPool(parseFloat(bonus).toFixed(4));
      });
    })

    document.addEventListener("DOMContentLoaded", async function() {
      const statut = await checkServerSeedStatut();
      // if (statut == 0) {
      //   console.log("statut ok");
      // }
      // else{
      //   alert("Previous seed phrase already found, just wait for the next one! Check our social network to be aware.");
      //   return
      // }
    });    

    window.addEventListener('focus', function() {
      if(defaultAccount) {
        clearInterval(myDelay);
        setTimeToWait(defaultAccount);
      }
    });

    window.addEventListener('beforeunload', function(event) {    
      event.preventDefault(); 
    });

    useEffect(() => {      
      if (remainingTime > 0)
      {
        if (isButtonEnabled) {
          setIsButtonEnabled(false);
        }
        clearInterval(myDelay);
        setMyDelay(setInterval(updateButtonLabel, 1000)); 
      }
      else
      {
        setIsButtonEnabled(true);
        setButtonLabel("free");
        setButtonMmLabel("mastermind");
      }
    }, [remainingTime]);

    const connectwalletHandler = async() => {
      if (window.ethereum) {
        try {
          await askForAccount();
          await accountChangedHandler(provider.getSigner());
        } catch (error) {
          console.error("askForAccount failed: ", error);
        }
      } else {
        setErrorMessage("Please Install Metamask");
        alert("Please install Metamask")
      }
    }

    const accountChangedHandler = async (newAccount) => {
      getPrizePool().then((pool) => {
        setPrizePool(parseFloat(pool).toFixed(4));
      });
  
      getBonus().then((bonus) => {
        setBonusPool(parseFloat(bonus).toFixed(4));
      });
      const address = await newAccount.getAddress();
      setDefaultAccount(address);
      const balanceWei = await getBalance(address);
      const balance = ethers.utils.formatEther(balanceWei);
      setUserBalance(parseFloat(balance).toFixed(3));
      setTimeToWait(address);
    }

    const setTimeToWait = async (address) => {
      const t = await getTryTime(address);
      const now = new Date();
      const unixTimestamp = Math.floor(now.getTime() / 1000);
      const remainingT = unixTimestamp - t >= waitingTime ? 0 : waitingTime - (unixTimestamp - t);
      setRemainingTime(remainingT);
    }

    const updateButtonLabel = () => {
      if (remainingTime > 1) {
        const rT = remainingTime - 1;
        setButtonLabel(rT.toString());
        setButtonMmLabel(rT.toString());
        setRemainingTime(rT);
      } else {
        clearInterval(myDelay);
        setButtonLabel("free");
        setButtonMmLabel("mastermind");
        setIsButtonEnabled(true);
      }
    };

    const switchTestMode = async() => {
      if (window.ethereum) {
        askForAccount().then(() => {
          setTestMode(!testMode);
          setDefaultAccount(null);
          setUserBalance(null);
        });
        if (networkId == networkIdSepolia) {
          networkId = networkIdGoerli;
          provider = providerGoerli;    
          contract = contractGoerli;   
        }
        else {
          networkId = networkIdSepolia;
          provider = providerSepolia;
          contract = contractSepolia;
        }
      }
      else {
        alert("Please install Metamask");
      }
    }

    function callServerWithRetry(url, id) {
    
      function performRequest() {
    
        makeRequest(url)
          .then(response => {
            console.log('Reponse:', response.data);
            const data = response.data;
            if(data.includes("ERROR")) {
              const regex = /ERROR : (.+)/;
              const match = regex.exec(data);

              if (match && match.length >= 2) {
                const errorLine = match[1];
                console.log(errorLine);

                if (errorLine === "API ERROR") {
                  alert("An error occured communicating with API, click the OK button to retry");
                  performRequest();
                }
                else if (errorLine === "RECALL") {
                  alert("You already get answered");
                }
                else if (errorLine === "INDICES") {
                  alert("Empty phrase");
                }
                else if (errorLine === "BAD HASH") {
                  alert("Transaction hash not correct");
                }
              } else {
                alert("Error not found");
              }
            }
            else
            {
              const indexOfBracket = JSON.stringify(data).indexOf("[");
              if (indexOfBracket !== -1) {
                const substring = JSON.stringify(data).substring(indexOfBracket);
                const dataHalfCleaned = substring.replace(/\[/g, "");
                const datacleaned = dataHalfCleaned.split("]");
                let nbGoodAns = 0;
                let alertResp = "Your correct words are: ";
                for (let i = 0; i < 12; i++) {
                  const element = datacleaned[i];
                  if (element === "" || element.length === 0) {
                    nbGoodAns = nbGoodAns;
                  }
                  else {
                    const idWord = i + 1;
                    alertResp = alertResp + "word " + idWord + ": " + element + ", ";
                    nbGoodAns = nbGoodAns + 1;
                  }
                }
                if (id === 0) {
                  if (nbGoodAns === 12) {
                    alertResp = "Well done! You found the seed phrase";
                  }
                  else
                  {
                    alertResp = "Try again";
                  }
                }
                else {
                  if (nbGoodAns === 12) {
                    alertResp = "Well done! You found the seed phrase";
                  }
                  else if(nbGoodAns === 0) {
                    alertResp = "No right word";
                  }
                }
                showDialog(alertResp);
                setErrorMessage(alertResp);
              } else {
                console.log("[ not found");
              }
            }          
          })
          .catch(error => {
            alert("server call failed");
            console.error("server call failed with error: " + error);
          });
      }
    
      performRequest();
    }

    const handleDepositAmountChange = (event) => {
      setDepositAmount(event.target.value);
    };

    const handleWithdrawAmountChange = (event) => {
      setWithdrawAmount(event.target.value);
    }

    const handleInputChange = (event, index) => {
      const { value } = event.target;
      const newWords = [...words];
      if (value.split(" ").length > 1) {
        setErrorMessage(`${value} can only contain 1 word, without any space`);
      } else {
        setErrorMessage("");
      }
      newWords[index] = value;
      setWords(newWords);
    };
  
    const handleFormSubmit = (event, id) => {
      event.preventDefault();
      if (window.ethereum) {
          askForAccount().then(async () => {
              if(defaultAccount == null) {
                alert("Please connect your metamask wallet first.");
                return;
              }
              const signerAddress = await provider.getSigner().getAddress();
              if(signerAddress !== defaultAccount) {
                alert("Please switch your metamask account to " + defaultAccount + " or refresh to connect with current metamask address.");
                return;
              }
              if (!isButtonEnabled) {
                setErrorMessage(`you didn't wait enough since your last try`);
                return;
              }

              if(id === 3) {
                const balWei = await getBalance(defaultAccount);
                const mmPriceWei = ethers.utils.parseEther(mmPrice);
                if (balWei < mmPriceWei) {
                  alert("You need at least 0.001 eth on your balance to use mastermind");
                  return;
                }
              }
          
              if (words.includes("")) {
                setErrorMessage(`please fill the whole form`);
                return
              }

              else {

                if(testMode) {
                  propose(id).then( (transaction) => {
                    checkTransactionStatus(transaction.hash).then(async (isTransactionSuccessful) => {
                      if (isTransactionSuccessful) {
                        setTimeToWait(transaction.from);
                        const balanceWei = await getBalance(defaultAccount);
                        const balance = ethers.utils.formatEther(balanceWei);

                        setUserBalance(parseFloat(balance).toFixed(3));
                        
                        const oneStringWords = words.join(" ");
                        if (oneStringWords === TEST_SEED_PHRASE) {
                          alert("Congratulations! If you were on the Ethereum Mainnet, you would have won 0.05 ETH plus the bonus. So, switch to Mainnet!");
                        }
                        else if (id === 0) {
                          alert("Wrong seed phrase, try again");
                        }
                        else if (id === 3) {
                          const seedPhraseTestSplit = TEST_SEED_PHRASE.split(" ");
                          let alertResp = "Your correct words are: ";
                          for(let i = 0; i < 12; i++) {
                            const idWord = i + 1;
                            if (words[i] === seedPhraseTestSplit[i]) {
                              alertResp = alertResp + "word " + idWord + ": " + words[i] + ", ";
                            }
                          }
                          alert(alertResp);
                        }
                        return;
                      }
                      else
                      {
                        setErrorMessage('transaction failed');
                        return;
                      }
                    })
                  })
                }
                else
                {
                  const statut = await checkServerSeedStatut();
                  // if (statut == 0) {
                  //   console.log("statut ok");
                  // }
                  // else{
                  //   alert("Previous seed phrase already found, just wait for the next one! Check our social network to be aware.");
                  //   return
                  // }
                  propose(id).then( (transaction) => {
                    checkTransactionStatus(transaction.hash)
                      .then(async (isTransactionSuccessful) => {
                        if (isTransactionSuccessful) {
                          setTimeToWait(transaction.from);
                          const balanceWei = await getBalance(defaultAccount);
                          const balance = ethers.utils.formatEther(balanceWei);
                          setUserBalance(parseFloat(balance).toFixed(3));  

                          const url = "https://api01.guesstheseed.com/verif.php?hash=" + transaction.hash + "&indice1=" + words[0].toLowerCase().replace(/\s/g, "") + "&indice2=" + words[1].toLowerCase().replace(/\s/g, "") + "&indice3=" + words[2].toLowerCase().replace(/\s/g, "") + 
                                  "&indice4=" + words[3].toLowerCase().replace(/\s/g, "") + "&indice5=" + words[4].toLowerCase().replace(/\s/g, "") + "&indice6=" + words[5].toLowerCase().replace(/\s/g, "") + "&indice7=" + words[6].toLowerCase().replace(/\s/g, "") + "&indice8=" + words[7].toLowerCase().replace(/\s/g, "") + 
                                  "&indice9=" + words[8].toLowerCase().replace(/\s/g, "") + "&indice10=" + words[9].toLowerCase().replace(/\s/g, "") + "&indice11=" + words[10].toLowerCase().replace(/\s/g, "") + "&indice12=" + words[11].toLowerCase().replace(/\s/g, "");
                          console.log(url);
                          callServerWithRetry(url, id);
                        }
                        else
                        {
                          setErrorMessage('transaction failed');
                        }
                      })
                      .catch((error) => {
                        console.error('An error occured: ', error);
                      });
                  })
                }
              }
            });
          }
        else {
          alert("Please install Metamask");
        }
    };
  
    const handleDeposit = () => {
      if (window.ethereum) {
          askForAccount().then(async () => {
              if(defaultAccount == null) {
                alert("Please connect your metamask wallet first.");
                return;
              }
              const signerAddress = await provider.getSigner().getAddress();
              if(signerAddress !== defaultAccount) {
                alert("Please switch your metamask account to " + defaultAccount + " or refresh to connect with current metamask address.");
                return;
              }
              const balWei = await provider.getBalance(defaultAccount);
              const depositAmountWei = ethers.utils.parseEther(depositAmount);
              if (balWei < parseFloat(depositAmountWei)) {
                alert("Overdrawn balance.");
                return;
              }
              const ad = await provider.getSigner().getAddress();
              deposit(depositAmount, ad).then( (transaction) => {
                checkTransactionStatus(transaction.hash)
                  .then(async (isTransactionSuccessful) => {
                    if (isTransactionSuccessful) {
                      const balanceWei = await getBalance(defaultAccount);
                      const balance = ethers.utils.formatEther(balanceWei);
                      setUserBalance(parseFloat(balance).toFixed(3));
                    }
                    else
                    {
                      setErrorMessage('transaction failed');
                    }
                  })
                  .catch((error) => {
                    console.error('An error occured: ', error);
                  });
              });
          })
      } else {
        setErrorMessage("Please Install Metamask");
        alert("Please install Metamask");
      }
    };

    const handleWithdraw = () => {
      if (withdrawAmount === "" || withdrawAmount === 0) {
        return
      }
      if (window.ethereum) {
          askForAccount().then(async () => {
              if(defaultAccount == null) {
                alert("Please connect your metamask wallet first.");
                return;
              }
              const signerAddress = await provider.getSigner().getAddress();
              if(signerAddress !== defaultAccount) {
                alert("Please switch your metamask account to " + defaultAccount + " or refresh to connect with current metamask address.");
                return;
              }
              const balWei = await getBalance(defaultAccount);
              const withdrawAmountWei =  ethers.utils.parseEther(withdrawAmount);
              if (balWei < parseFloat(withdrawAmountWei)) {
                alert("Overdrawn balance.");
                return;
              }
              const ad = await provider.getSigner().getAddress();
              withdraw(withdrawAmount, ad).then( (transaction) => {
                checkTransactionStatus(transaction.hash)
                  .then(async (isTransactionSuccessful) => {
                    if (isTransactionSuccessful) {
                      const balanceWei = await getBalance(defaultAccount);
                      const balance = ethers.utils.formatEther(balanceWei);
                      setUserBalance(parseFloat(balance).toFixed(3));
                    }
                    else
                    {
                      setErrorMessage('transaction failed');
                    }
                  })
                  .catch((error) => {
                    console.error('An error occured: ', error);
                  });
              });
          })
      } else {
        setErrorMessage("Please Install Metamask!!!");
        alert("Please install Metamask");
      }
    };

    const howTo = async () => {
      showDialog("1)Connect your metamask wallet.\n\n2)Deposit some eth to save gas if you want to use mastermind.\n\n3)Type the 12 seed words based on their definition.\n\n4)Choose free or mastermind plan.\n<b>Free</b>: indicates if your 12 words are all rights.\n<b>Mastermind</b>: indicates right words.\n-Please do not close or refresh the page until you have received the server's response-\n\n5)If you chose mastermind, don't forget to note down your right words!");
    }

    const registerNewSet = (event) => {
      event.preventDefault();
      if (window.ethereum) {
        askForAccount().then(async () => {
          if(defaultAccount == null) {
            alert("Please connect your metamask wallet first.");
            return;
          }

          const signerAddress = await provider.getSigner().getAddress();
          if(signerAddress !== defaultAccount) {
            alert("Please switch your metamask account to " + defaultAccount + " or refresh to connect with current metamask address.");
            return;
          }

          if (words.includes("")) {
            setErrorMessage(`please fill the whole form`);
            return
          }
          else
          {
            if(setOfWords.length >= 15) {
              alert("you cannot add more set of words");
              return
            }
            const newListOfSetOfWords = [...setOfWords, words];
            setsetOfWords(newListOfSetOfWords);
          }
        })
      } else {
        setErrorMessage("Please Install Metamask!!!");
      }
    }

    const removeSetFromList = (event, ind) => {
      event.preventDefault();
      const listCopy = [...setOfWords];
      listCopy.splice(ind, 1);
      setsetOfWords(listCopy);
    }

    return (
        <div className="Interact">
            <div className="container">
              <Button className="how-button" onClick={howTo}>How to</Button>
              {/* <Button className="test-mode" style={{ background: testMode ? "#A5CC82" : "white" }} onClick={switchTestMode}>Test</Button> */}
            </div>
            <h3 className="Presentation">
            Welcome to a fun game! <br />Prepare your metamask wallet and your favorite dictionnary <br />{testMode ? "You are in test mode, use Sepolia network" : ""}
            </h3>
            <div className="logo-container">
              <img src={applogo} className="App-logo" alt="logo" />
            </div>
            <h3 className="h4">
            Prize pool: {prizePool} ETH + Bonus: {bonusPool} ETH
            </h3>
            <Button
                style={{ background: defaultAccount ? "#A5CC82" : "white" }}
                onClick={connectwalletHandler}>
                {defaultAccount ? "Connected" : "Connect"}
            </Button>
            <div className="displayAccount">
                {/* <h4 className="walletAddress">Address:{defaultAccount}</h4> */}
                <div className="balanceDisplay">
                    <h3>
                        Balance: {userBalance} ETH
                    </h3>
                </div>
            </div>
            <div className="deposit">
              <input type="number" min="0" max={userBalance} value={depositAmount} onChange={handleDepositAmountChange} />
              <button onClick={handleDeposit}>Deposit</button>
              <input type="number" min="0" max={userBalance} value={withdrawAmount} onChange={handleWithdrawAmountChange} />
              <button onClick={handleWithdraw}>Withdraw</button>
            </div>
            
            <form>
              {words.map((word, index) => (
                <div key={index}>
                  <br />
                  <label htmlFor={`word-${index}`}>{definitions[index]}<br />{`Word ${index + 1}`}</label>
                  <input
                    type="text"
                    id={`word-${index}`}
                    name={`word-${index}`}
                    value={word}
                    onChange={(event) => handleInputChange(event, index)}
                  />
                </div>
              ))}
              <br />
              <button type="submit" id = "0" onClick={ e => handleFormSubmit(e, 0) }>{buttonLabel}</button>
              <button type="submit" id = "3" onClick={ e => handleFormSubmit(e, 3) }>{buttonMmLabel}</button>
              {/* <button type="submit" id = "3" onClick={ e => checkStatus() }>status</button>
              <button type="submit" onClick={ e => registerNewSet(e) }>register set</button> */}
              <br />
              {setOfWords.map((setOfWords, index) => (
                <div key={index}>
                  <p>{setOfWords[0]}, {setOfWords[1]}, {setOfWords[2]}, {setOfWords[3]}, {setOfWords[4]}, {setOfWords[5]}, {setOfWords[6]}, 
                  {setOfWords[7]}, {setOfWords[8]}, {setOfWords[9]}, {setOfWords[10]}, {setOfWords[11]} <button onClick={e => removeSetFromList(e,index)}>Remove</button>
                  </p>
                </div>
              ))}
            </form>
            {errorMessage}
            <div className="description">
                <h5>
                  Mastermind says right words for 0.001 ETH
                </h5>
            </div>
            <div className='sepolia-faucet'>
              <a href="https://sepoliafaucet.com/">get test token</a>
            </div>
        </div>
    )
}
export default Interact;