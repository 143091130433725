import Interact from "./Interact";
import "./App.css";

function App() {
  return (
    <div className="App-header">
      <div className="centerCard">
        <div className = "card">
          <div className="App">
            <Interact/>
          </div>
        </div>
      </div>
    </div>
  );
}
export default App;